import React from "react";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner, Label, Card } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "./countries";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaEnvelope } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import { apiUrl, apiToken } from "../constants";

function Register({ type, source }) {
  const urlParams = new URLSearchParams(window.location.search);
  const [redirect, setRedirect] = React.useState(false);
  const utm_source = urlParams.get("utm_source");
  let utmSource;
  if (utm_source) {
    localStorage.setItem("utmSource", utm_source);
    utmSource = utm_source;
  } else {
    utmSource = localStorage.getItem("utmSource");
  }

  const promo = urlParams.get("promo");
  const [details, setDetails] = React.useState({
    type,
    utmSource: source || utmSource,
    promo,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onChangeDetailsUdf = (e) => {
    setDetails({ ...details, udf: { ...details.udf, [e.target.name]: e.target.value } });
  };

  const onChangeAttendMode = (e) => {
    let passes = [];
    if (e.target.value === "CONFERENCE_ONLY") {
      passes = ["CONFERENCE"];
    }
    if (e.target.value === "CONFERENCE_WITH_WORKSHOP") {
      passes = ["CONFERENCE", "WORKSHOP"];
    }
    setDetails({ ...details, passes, udf: { ...details.udf, [e.target.name]: e.target.value } });
  };

  const checkboxChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.checked });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }
    if (!details.privacyPolicy) {
      setLoading(false);
      setError("Please accept our privacy policy");
      return null;
    }
    if (!details.country) {
      setLoading(false);
      setError("Please select a country from the dropdown");
      return null;
    }

    fetch(`${apiUrl}/setDelegateData`, {
      method: "POST",
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
        // setSuccess(
        //   "Thank you. We have received your request, our team will get back to you shortly."
        // );
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }

  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    { name: "Conference only", price: "100 GBP" },
    { name: "Conference and Workshops", price: "250 GBP" },
    { name: "Vendors - Conference only", price: "400 GBP" },
  ];
  return (
    <>
      <div
        style={{
          background: "#020910",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container className="my-auto text-white text-400 p-2">
          <Row className="justify-content-center">
            <Col lg={5}>
              <img
                src={require(`assets/images/logo/elogo.png`)}
                style={{ maxWidth: "340px" }}
                width="100%"
                alt="dt22"
              />

              <h1
                className="text-700 text-white mt-3"
                style={{
                  fontSize: size ? "1.7rem" : "1.25rem",
                  // width: size ? "480px" : "330px",
                }}
              >
                ROYAL MAXIM PALACE KEMPINSKI, CAIRO
              </h1>
              <div className="d-flex mt-3">
                <div className="mr-4">
                  <div style={{ backgroundColor: "#fbba14", padding: "2px" }}>
                    <h1
                      className="text-700 text-white m-0"
                      style={{
                        fontSize: size ? "1.2rem" : "1.25rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      CONFERENCE
                    </h1>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h1
                      className="text-700 text-white m-0 border-right pr-2"
                      style={{
                        fontSize: "3rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      4
                    </h1>
                    <h1
                      className="text-700 text-white m-0 pl-2 align-self-center"
                      style={{
                        fontSize: "1rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      FEB
                      <br />
                      2025
                    </h1>
                  </div>
                </div>
                <div>
                  <div style={{ backgroundColor: "#ceb739", padding: "2px" }}>
                    <h1
                      className="text-700 text-white m-0"
                      style={{
                        fontSize: size ? "1.2rem" : "1.25rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      WORKSHOPS
                    </h1>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h1
                      className="text-700 text-white m-0 border-right pr-2"
                      style={{
                        fontSize: "3rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      5
                    </h1>
                    <h1
                      className="text-700 text-white m-0 pl-2 align-self-center"
                      style={{
                        fontSize: "1rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      FEB
                      <br />
                      2025
                    </h1>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              {type === "REGISTER" && <p></p>}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center text-white">
                  <Col lg={3} className="my-2">
                    <label>First Name*</label>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={3} className="my-2">
                    <label>ِLast Name*</label>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Office Email*</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Phone*</label>
                    <PhoneInput
                      country={"eg"}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="w-100"
                      dropdownClass="text-dark"
                      value={details.phone}
                      onChange={(phone) => {
                        setDetails({ ...details, phone });
                      }}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Job Title*</label>
                    <Input
                      placeholder="Job Title"
                      type="text"
                      name="title"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Company*</label>
                    <Input
                      placeholder="Company"
                      type="text"
                      name="company"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Country *</label>
                    <Typeahead
                      onChange={(country) => {
                        setDetails({ ...details, country: country[0] });
                      }}
                      placeholder="Country"
                      options={countries}
                    />
                  </Col>

                  <Col lg={12} className="my-2">
                    <label>What profile you belong to? *</label>
                    <Input type="select" name="profile" onChange={onChangeDetailsUdf} required>
                      <option value="">Select</option>
                      <option>Project owner</option>
                      <option>Developer</option>
                      <option>Contractor</option>
                      <option>Architecture </option>
                      <option>Engineering</option>
                      <option>Consultant</option>
                      <option>Facility Management</option>
                      <option>Software/Tech provider</option>
                    </Input>
                  </Col>

                  {/* <Col lg={6} className="my-2">
                    <label>Promo Code</label>
                    <Input
                      placeholder="Promo Code"
                      type="text"
                      name="promo"
                      value={details.promo}
                      onChange={onChangeDetails}
                    />
                  </Col> */}
                  <Col lg={6} className="my-2">
                    <label>No. of Delegates </label>
                    <Input type="select" name="delegateCount" onChange={onChangeDetailsUdf}>
                      <option value="">Select</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>other</option>
                    </Input>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>I wish to attend:</label>
                    <Input type="select" name="attendMode" onChange={onChangeAttendMode}>
                      <option value="">Select</option>
                      <option value="CONFERENCE_ONLY">Conference only</option>
                      <option value="CONFERENCE_WITH_WORKSHOP">Conference + Workshop</option>
                    </Input>
                  </Col>

                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input type="checkbox" name="optIn" onChange={checkboxChange} /> I authorize
                        Saifee events to share my name, organization details, email and phone number
                        with the Partners & Sponsors of the 4th Annual DIGITAL TWIN 2025, Dubai, to
                        help them communicate with me with personalized communications about
                        products, services, and events. I understand I may opt-out or manage my
                        preferences at any time.
                      </Label>
                    </div>
                  </Col>
                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input type="checkbox" name="privacyPolicy" onChange={checkboxChange} />
                        Protecting your privacy is important to Saifee Events. We collect data
                        directly from you when you will Register for this event. The information we
                        procure includes your name, organization details, email and phone number
                        which is strictly kept confidential with us and will only be shared{" "}
                        <b>with the Partners & Sponsors of the Digital Twin 2025 Egypt</b> event, to
                        help them communicate with you their relevant offerings. By clicking submit,
                        I acknowledge the Saifee Events{" "}
                        <a
                          href="http://www.saifee-events.com/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#6ac2be" }}
                          className="text-400"
                        >
                          <b>Privacy </b>
                        </a>
                        Statement
                      </Label>
                    </div>
                    <div className="ml-4 mt-2">
                      <Label check>
                        <br />
                        <b>Please note</b>: Filling out the form does not guarantee admission to the
                        event.
                        <br />
                        Our Team will evaluate the registration based on the attendee eligibility
                        criteria and contact you should your submission be accepted.
                      </Label>
                    </div>
                    {loading ? (
                      <span>
                        <Spinner className="mt-3" color="warning" size="sm" />
                      </span>
                    ) : (
                      <Button
                        block
                        className="btn text-white text-700 mt-3"
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={loading}
                        style={{ background: "#ceb739" }}
                      >
                        Submit
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <CreateAlert success={success} error={error} />
      </div>
    </>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;
